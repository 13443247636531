import i18n from '@/i18n'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import { filterRulesMapped } from '@/tools/filterRules'
import vinculationApi from '@/api/vinculation.api'
import thingApi from '@/api/thing.api'
import { mapMutations, mapActions } from 'vuex'
import { SnackbarStyle, DialogType } from '@/constants/constants'
import equal from 'deep-equal'
import deepcopy from 'deepcopy'
import { removeChildren, getIds, getItemsById, getChildrenFromList } from '@/tools/functions'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'

export default {
  name: 'VinculationABMComponent',
  components: {
    ListTreeViewComponent,
    SelectorDialog
  },
  data: () => ({
    /**
    *   "_id" : ObjectId("5e2efe0997ad763e58f7da46"),
    *   "name": "VINCULACION-1"
    *   "v1" : "5e163867e70a3b6cdcf7a76f",
    *   "v2" : "5e16387ce70a3b6cdcf7a770",
    *   "v1State" : "5e2ef78b730ea6907dae1ca9",
    *   "v2State" : "5e2ef794730ea6907dae1caf",
    *   "permanent" : "false",
    *   "timeTolerance" : 6,
    *   "distanceTolerance" : 6,
    *   "timePermanence" : 9,
    *   "exceptions" : [],
    *   "alert" : 1,
    *   "active" : false
    */
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    rowActions: [
      {
        id: 1,
        name: i18n.t('modify'),
        color: 'blue',
        icon: 'fa-pen'
      },
      {
        id: 2,
        name: i18n.t('delete'),
        color: 'red',
        icon: 'fa-trash-alt'
      }
    ],
    tableComponentButtons: [],
    queryPageSize: undefined,
    queryPageNumber: undefined,
    queryId: undefined,
    validForm: false,
    validationRules: {
      required: (value) => !!value || `${i18n.t('required')}`,
      requiredDefault: (value, defaultValue) => !!value || value === defaultValue || `${i18n.t('required')}`,
      min: (value, minValue) => value >= minValue || `${i18n.t('minNumber', { min: minValue })}`,
      max: (value, maxValue) => value <= maxValue || `${i18n.t('maxNumber', { max: maxValue })}`,
      requiredVinculationPermanence: (value, timeConfiguration) => (timeConfiguration && !!value) || (!timeConfiguration) || `${i18n.t('required')}`,
      nameWhenUpdate: (value, ruleToUpdate) => (ruleToUpdate && value !== ruleToUpdate.name) || !ruleToUpdate || `${i18n.t('vinculation.differentNameWhenUpdate')}`
    },
    name: undefined,
    distanceTolerance: undefined,
    timeToleranceEnabled: false,
    timeTolerance: undefined,
    permanent: 'permanent',
    alert: 'alertByVinculation',
    timeConfiguration: false,
    vinculationPermanence: undefined,
    exceptions: [],
    originThings: [],
    destinationThings: [],
    ruleToUpdate: undefined,
    stepper: 1,
    pageTotal: 0,
    showEditDialog: false,
    editDialogTitle: i18n.t('vinculation.editDialog.titleNew'),
    loadingTable: true,
    originSelectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      },
      data: [],
      selected: [],
      show: false,
      title: i18n.t('selector.selectionDialog.titleDefault'),
      singleSelect: false,
      selectAll: false,
      saveDisabled: false
    },
    destinationSelectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      },
      data: [],
      selected: [],
      show: false,
      title: i18n.t('selector.selectionDialog.titleDefault'),
      singleSelect: false,
      selectAll: false,
      saveDisabled: false
    },
    nameError: [],
    // para validación de name
    actualName: null
  }),
  computed: {
    disableSaveButton () {
    },
    disableSelectButton () {
      if (this.ruleToUpdate) {
        return true
      } else {
        return false
      }
    },
    /**
     * Cuando se actualiza una regla, deshabilita el botón si no se hicieron cambios en el formulario
     */
    disableWhenUpdate () {
      return (
        this.ruleToUpdate &&
        this.ruleToUpdate.name === this.name &&
        this.ruleToUpdate.distanceTolerance === Number(this.distanceTolerance) &&
        ((this.ruleToUpdate.timeToleranceEnabled != null && this.ruleToUpdate.timeToleranceEnabled === this.timeToleranceEnabled) ||
          (this.ruleToUpdate.timeToleranceEnabled == null && this.ruleToUpdate.timeToleranceEnabled === false)) &&
        this.ruleToUpdate.timeTolerance === Number(this.timeTolerance) &&
        ((this.ruleToUpdate.permanent === i18n.t('yes') && this.permanent === 'permanent') || (this.ruleToUpdate.permanent === i18n.t('no') && this.permanent === 'notPermanent')) &&
        ((this.ruleToUpdate.alert === i18n.t('vinculation.vinculation') && this.permanent === 'notPermanent' && this.alert === 'alertByVinculation') ||
        (this.ruleToUpdate.alert === i18n.t('vinculation.disengagement') && this.permanent === 'notPermanent' && this.alert === 'alertByDisengagement') ||
        (this.ruleToUpdate.alert === '-' && this.permanent === 'permanent')) &&
        this.ruleToUpdate.timePermanence === Number(this.vinculationPermanence) &&
        equal(this.ruleToUpdate.exceptions, this.exceptions)
      )
    },
    stepperSaveLabel () {
      if (this.stepper < 2) {
        return i18n.t('next')
      } else {
        return i18n.t('save')
      }
    },
    stepperCancelLabel () {
      if (this.stepper > 1) {
        return i18n.t('back')
      } else {
        return i18n.t('cancel')
      }
    }
  },
  created () {
    // Si hay query params, los setea para incluirlos en la llamada al backend, de lo contrario, busca todas las reglas de vinculación
    if (this.$route.query) {
      this.queryPageSize = this.$route.query.pageSize ? Number(this.$route.query.pageSize) : undefined
      this.queryPageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : undefined
      this.queryId = this.$route.query.id ? this.$route.query.id : undefined
    }
    // Valida que los qery params sean correctos y completos
    if ((this.queryPageSize && this.queryPageSize <= 0) || (this.queryPageNumber && this.queryPageNumber <= 0) || (this.queryPageNumber && !this.queryPageSize) || (!this.queryPageNumber && this.queryPageSize)) {
      this.showSnackbar({ visible: true, text: this.$t('error.invalidQueryParams'), timeout: 6000, style: SnackbarStyle.ERROR })
      this.queryPageSize = undefined
      this.queryPageNumber = undefined
    }
    // Botón de eliminación múltiple
    this.tableComponentButtons.push(
      {
        id: 'edit-dialog',
        tooltip: i18n.t('add'),
        icon: 'add',
        action: this.showDialog.bind(this),
        selectedData: false
      },
      {
        id: 'masive-delete',
        tooltip: i18n.t('vinculation.multipleDelete'),
        icon: 'delete_outline',
        action: this.multipleDelete.bind(this),
        selectedData: true
      })
    // Obtiene todas las equipos de la base de datos
    this.getThings()
    // Obtener todas las vinculaciones y la vinculación para editar (si se utilzia el query param)
    this.resetFields()
    this.getData()
    if (this.queryId) {
      this.getVinculationById(this.queryId)
    }
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Resize de los split panel
     */
    resize () {
    },
    /**
     * Click en una fila
     * @param {*} data
     */
    clickRow () {
    },
    /**
     * Refrescar la data
     */
    refreshTable () {
      this.getData(true)
    },
    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {
    },
    cancelDialogAction () {
      if (this.originSelectorDialogModel.show) {
        this.originSelectorDialogModel.show = false
      } else {
        this.destinationSelectorDialogModel.show = false
      }
    },
    getData (refresh = false) {
      this.loadingTable = true
      vinculationApi.getTotalCount().then(response => {
        this.pageTotal = Number(response.data)
      })
      vinculationApi.getPaginated(this.queryPageSize, this.queryPageNumber).then(response => {
        this.tableData.cleanAndUpdate(response.data.data)
        if (!refresh) {
          this.tableHeaders.cleanAndUpdate(response.data.headers)
          this.setTableFilterRules()
        }
        this.loadingTable = false
      })
    },
    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    /**
     * Resetea los campos del formulario de creación/actualización
     */
    async resetFields () {
      this.name = ''
      this.distanceTolerance = 0
      this.timeToleranceEnabled = false
      this.timeTolerance = 0
      this.permanent = 'permanent'
      this.alert = 'alertByVinculation'
      this.timeConfiguration = false
      this.vinculationPermanence = 0
      this.originThings = []
      this.destinationThings = []
      this.originSelectorDialogModel.selected = []
      this.destinationSelectorDialogModel.selected = []
      this.ruleToUpdate = undefined
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.validForm = false
      this.originSelectorDialogModel.selected.clean()
      this.destinationSelectorDialogModel.selected.clean()
      this.stepper = 1
      this.showEditDialog = false
      this.editDialogTitle = i18n.t('vinculation.editDialog.titleNew')
    },
    getVinculationById (id) {
      vinculationApi.getById(id).then(response => {
        this.loadFields(response.data)
      }).catch(() => {
        this.resetFields()
      })
    },
    async delete (data) {
      await vinculationApi.delete(data._id)
      this.getData(true)
    },
    async multipleDelete (data) {
      this.openDialog({
        title: this.$t('delete'),
        text: this.$t('vinculation.multipleDeleteMessage'),
        type: DialogType.QUESTION,
        overlayOpacity: 0.5,
        actionButtons: true,
        yesAction: async () => {
          const ids = data.map(e => e._id)
          await vinculationApi.multipleDelete(ids)
          this.getData(true)
          this.$refs['vinculations-table'].resetSelected()
        },
        noAction: () => { }
      })
    },
    loadFields (data) {
      this.ruleToUpdate = data
      this.name = data.name
      this.actualName = this.name
      this.distanceTolerance = data.distanceTolerance
      this.timeToleranceEnabled = data.timeToleranceEnabled != null ? data.timeToleranceEnabled : false
      this.timeTolerance = data.timeTolerance
      const dialectNormalize = data.permanent.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      this.permanent = dialectNormalize === this.$t('yes') ? 'permanent' : 'notPermanent'
      this.alert = data.permanent === this.$t('no') && data.alert === this.$t('vinculation.vinculation') ? 'alertByVinculation' : (data.permanent === this.$t('no') && data.alert === this.$t('vinculation.disengagement') ? 'alertByDisengagement' : 'alertByVinculation')
      this.timeConfiguration = data.timePermanence > 0
      this.vinculationPermanence = data.timePermanence
      this.originThings = [{
        id: data.v1,
        name: data.v1Name,
        selected: false
      }]
      this.originSelectorDialogModel.selected.cleanAndUpdate(this.originThings.map(item => item.id))
      this.destinationThings = [{
        id: data.v2,
        name: data.v2Name,
        selected: false
      }]
      this.destinationSelectorDialogModel.selected.cleanAndUpdate(this.destinationThings.map(item => item.id))
      this.showDialog()
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          // TO DO: agregar el id como query param
          this.editDialogTitle = i18n.t('vinculation.editDialog.titleEdit')
          this.loadFields(data)
          break
        case 2:
          this.openDialog({
            title: this.$t('delete'),
            text: this.$t('vinculation.deleteMessage', { name: data.name, v1: data.v1Name, v2: data.v2Name }),
            type: DialogType.QUESTION,
            overlayOpacity: 0.5,
            actionButtons: true,
            yesAction: () => { this.delete(data) },
            noAction: () => { }
          })
          break
        default:
          console.log('default')
      }
    },
    clickOutside () {
      this.cancel()
    },
    cancel () {
      this.resetFields()
    },
    showDialog () {
      this.showEditDialog = true
    },
    save () {
      if (this.ruleToUpdate) {
        const ruleDto = {
          _id: this.ruleToUpdate._id,
          name: this.name,
          v1: this.ruleToUpdate.v1,
          v2: this.ruleToUpdate.v2,
          v1Name: this.ruleToUpdate.v1Name,
          v2Name: this.ruleToUpdate.v2Name,
          distanceTolerance: Number(this.distanceTolerance),
          timeToleranceEnabled: this.timeToleranceEnabled,
          timeTolerance: Number(this.timeTolerance),
          permanent: this.permanent === 'permanent',
          alert: this.permanent === 'notPermanent' && this.alert === 'alertByVinculation'
            ? 1
            : (this.permanent === 'notPermanent' && this.alert === 'alertByDisengagement' ? 2 : 0),
          timePermanence: this.timeConfiguration ? Number(this.vinculationPermanence) : Number(0),

          exceptions: this.ruleToUpdate.exceptions
        }
        if (this.ruleToUpdate.v1State) {
          ruleDto.v1State = this.ruleToUpdate.v1State
        }
        if (this.ruleToUpdate.v2State) {
          ruleDto.v2State = this.ruleToUpdate.v2State
        }
        console.log(ruleDto)
        vinculationApi.update(ruleDto).then(() => {
          this.showSnackbar({ visible: true, text: this.$t('vinculation.successUpdateMessage'), timeout: 6000, style: SnackbarStyle.SUCCESS })
          this.resetFields()
          this.getData(true)
        })
      } else {
        // CREATE
        const ruleDtos = this.getRuleDtos()
        vinculationApi.create(ruleDtos).then(() => {
          this.showSnackbar({ visible: true, text: this.$t('vinculation.successCreateMessage'), timeout: 6000, style: SnackbarStyle.SUCCESS })
          this.resetFields()
          this.getData()
        })
      }
    },
    getRuleDtos () {
      const result = []
      const originChildren = []
      getChildrenFromList(this.originThings, originChildren)
      const destinationChildren = []
      getChildrenFromList(this.destinationThings, destinationChildren)
      originChildren.forEach(originChild => {
        destinationChildren.forEach(destinationChild => {
          result.push({
            name: this.name,
            v1: originChild.id,
            v2: destinationChild.id,
            v1Name: originChild.name,
            v2Name: destinationChild.name,
            distanceTolerance: Number(this.distanceTolerance),
            timeToleranceEnabled: this.timeToleranceEnabled,
            timeTolerance: Number(this.timeTolerance),
            permanent: this.permanent === 'permanent',
            alert: this.permanent === 'notPermanent' && this.alert === 'alertByVinculation'
              ? 1
              : (this.permanent === 'notPermanent' && this.alert === 'alertByDisengagement' ? 2 : 0),
            timePermanence: Number(this.vinculationPermanence),
            exceptions: []
          })
        })
      })
      return result
    },
    getThings () {
      return thingApi.getNested().then(response => {
        this.originSelectorDialogModel.data.cleanAndUpdate(response.data)
        this.destinationSelectorDialogModel.data.cleanAndUpdate(response.data)
      })
    },
    async selectOriginThings () {
      await this.getThings()
      const originListChildren = []
      getChildrenFromList(this.originThings, originListChildren)
      const destinationListChildren = []
      getChildrenFromList(this.destinationThings, destinationListChildren)
      const selectedOriginIds = []
      getIds(originListChildren, selectedOriginIds)
      this.originSelectorDialogModel.selected.cleanAndUpdate(selectedOriginIds)
      removeChildren(this.originSelectorDialogModel.data, destinationListChildren)
      this.originSelectorDialogModel.show = true
    },
    async selectDestinationThings () {
      await this.getThings()
      const originListChildren = []
      getChildrenFromList(this.originThings, originListChildren)
      const destinationListChildren = []
      getChildrenFromList(this.destinationThings, destinationListChildren)
      const selectedDestinationIds = []
      getIds(destinationListChildren, selectedDestinationIds)
      this.destinationSelectorDialogModel.selected.cleanAndUpdate(selectedDestinationIds)
      removeChildren(this.destinationSelectorDialogModel.data, originListChildren)
      this.destinationSelectorDialogModel.show = true
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
    saveDialogAction (items) {
      const itemsCopy = deepcopy(items)
      const selectedItems = []
      if (this.originSelectorDialogModel.show) {
        getItemsById(this.originSelectorDialogModel.data, itemsCopy, selectedItems)
        this.originSelectorDialogModel.selected.cleanAndUpdate(itemsCopy)
        this.originThings.cleanAndUpdate(selectedItems)
        this.originSelectorDialogModel.show = false
      } else {
        getItemsById(this.destinationSelectorDialogModel.data, itemsCopy, selectedItems)
        this.destinationSelectorDialogModel.selected.cleanAndUpdate(itemsCopy)
        this.destinationThings.cleanAndUpdate(selectedItems)
        this.destinationSelectorDialogModel.show = false
      }
    },
    nextStep () {
      if (this.stepper < 2) {
        this.stepper++
      } else {
        this.save()
      }
    },
    lastStep () {
      if (this.stepper > 1) {
        this.stepper--
      } else {
        this.cancel()
      }
    },
    externalPaginator (pageSize, pageNumber) {
      this.queryPageNumber = Number(pageNumber)
      this.queryPageSize = Number(pageSize)
    },
    /**
     * Mensaje de error cuando el nombre de usuario está duplicado
     */
    nameDuplicatedMethod () {
      if (this.name === this.actualName) return
      vinculationApi.validateName(this.name).then(response => {
        if (response) {
          this.nameError.push(`${i18n.t('vinculation.nameDuplicate')}`)
        } else {
          this.nameError = []
        }
      })
    }
  },
  watch: {
    /*
    stepper () {
      if (this.stepper === 2 && this.ruleToUpdate !== undefined && this.$refs['form']) {
        this.$refs['form'].validate()
      }
    }
    */
  }
}
